.layout-container {
    display: flex;
    min-height: 100vh;
}

/* Sidebar styles */
.sidebar {
    width: 240px;
    background-color: white;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease;
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
}

    .sidebar.collapsed {
        width: 60px;
    }

.logo {
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.highlight-uf {
    color: #0021A5;
}

.toggle-btn {
    width: 100%;
    padding: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
}

    .toggle-btn:hover {
        color: #0021A5;
    }

.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

    .menu-list li a {
        display: flex;
        align-items: center;
        padding: 0.75rem 1rem;
        color: #333;
        text-decoration: none;
        gap: 1rem;
    }

        .menu-list li a:hover {
            background-color: #f5f5f5;
            color: #0021A5;
        }

.menu-icon {
    font-size: 1.25rem;
    min-width: 24px;
}

/* Content area styles */
.content {
    flex: 1;
    margin-left: 240px;
    transition: margin-left 0.3s ease;
    position: relative; /* Add this */
}

.sidebar.collapsed ~ .content {
    margin-left: 60px;
}

/* Header styles */
.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: white;
    border-bottom: 1px solid #eee;
    position: relative; /* Add this */
    z-index: 1000; /* Add this */
}

.header-title {
    margin: 0;
    font-size: 1.5rem;
}

/* Main content styles */
.layout-content {
    padding: 2rem;
    background-color: #f9f9f9;
    min-height: calc(100vh - 130px);
    position: relative; /* Add this */
    z-index: 1; /* Add this */
}

/* Footer styles */
.layout-footer {
    padding: 1rem 2rem;
    background-color: white;
    border-top: 1px solid #eee;
    text-align: center;
}

/* UserDropdown styles */
.user-dropdown {
    position: relative;
    z-index: 1010; /* Add this to ensure dropdown is above other elements */
}

.user-dropdown-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 1000; /* Match parent z-index */
}
