/* Header Section */
.landing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container img.uf-logo {
  height: 25px;
}
.highlight {
  color: #dd661e;
}

.nav-links a {
  margin: 0 1rem;
  text-decoration: none;
  color: #555;
  font-weight: bold;
}

.login-btn {
  background-color: transparent;
  border: 2px solid #0072ce;
  color: #0072ce;
}

.signup-btn {
  background-color: #0072ce;
  color: #fff;
}

/* Main Section */
.landing-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 2rem;
  background-color: #f0f4f8;
}

.main-title {
  font-size: 2.5rem;
  color: #222;
}

.description {
  font-size: 1.2rem;
  margin: 1rem 0 2rem;
  color: #555;
}

.cta-buttons .cta-button {
  margin: 0 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.shift-logo {
  position: relative;
  top: 2px;
  left: 0.25em;
}

.primary {
  background-color: #0072ce;
  color: #fff;
}

.secondary {
  background-color: transparent;
  color: #0072ce;
  border: 2px solid #0072ce;
}

.main-content {
  flex: 1;
  text-align: left;
  padding-right: 2rem;
}

.demo-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Team Section */
.team-section {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #fff;
}

.team-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.team-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1rem;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.team-photo img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 1rem;
}
