.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.home-header {
    text-align: center;
    margin-bottom: 3rem;
}

.highlight {
    color: #f37b21;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.dashboard-item {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

    .dashboard-item h3 {
        margin-bottom: 1.5rem;
        color: #2d3748;
        border-bottom: 2px solid #f0f0f0;
        padding-bottom: 0.5rem;
    }

/* Current Courses Section */
.current-courses {
    grid-column: 1 / -1;
}

.courses-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.course-card {
    background: #f8fafc;
    padding: 1rem;
    border-radius: 8px;
    border-left: 4px solid #f37b21;
}

    .course-card h4 {
        color: #2d3748;
        margin-bottom: 0.5rem;
    }

    .course-card p {
        margin: 0.25rem 0;
        color: #4a5568;
        font-size: 0.9rem;
    }

/* Schedule Section */
.schedule {
    grid-column: 1 / -1;
}

.schedule-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
}

.schedule-day {
    background: #f8fafc;
    padding: 1rem;
    border-radius: 8px;
}

    .schedule-day h5 {
        text-align: center;
        margin-bottom: 1rem;
        color: #2d3748;
    }

.schedule-event {
    background: white;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    border-left: 3px solid #f37b21;
}

    .schedule-event span {
        display: block;
        font-weight: bold;
        font-size: 0.9rem;
    }

    .schedule-event small {
        color: #718096;
    }

/* Quick Actions */
.quick-actions {
    grid-column: 1 / -1;
}

.action-buttons {
    display: flex;
    gap: 1rem;
}

.action-button {
    flex: 1;
    background: #f37b21;
    color: white;
    padding: 1rem;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s;
}

    .action-button:hover {
        background: #e06912;
    }

.empty-state {
    text-align: center;
    color: #718096;
    padding: 2rem;
}

@media (max-width: 768px) {
    .dashboard-grid {
        grid-template-columns: 1fr;
    }

    .schedule-grid {
        grid-template-columns: repeat(3, 1fr);
        overflow-x: auto;
    }
}

.quick-actions {
    text-align: center;
}

.action-buttons {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.search-courses-btn {
    background-color: #0021A5; /* UF Blue */
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

    .search-courses-btn:hover {
        background-color: #001A83; /* Darker UF Blue */
    }

/* Auto Schedule Button Styles */
.auto-schedule-button-container {
    margin-top: 1.5rem;
    text-align: center;
}

.auto-schedule-button {
    background-color: #0056b3;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

    .auto-schedule-button:hover {
        background-color: #004494;
        transform: scale(1.05);
    }

/* Auto Scheduler Container Styles */
.auto-scheduler-container {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    padding: 1rem;
}

/* Auto Scheduler Component Styles */
.auto-scheduler {
    padding: 1rem;
}

.auto-scheduler-header {
    margin-bottom: 1.5rem;
}

    .auto-scheduler-header h3 {
        font-size: 1.25rem;
        font-weight: 600;
        color: #333;
    }

.recommended-sections {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.section-card {
    background-color: #f8f9fa;
    border-radius: 0.5rem;
    padding: 1rem;
    border: 1px solid #e9ecef;
}

    .section-card h4 {
        margin-bottom: 0.5rem;
        color: #0056b3;
    }

.section-details {
    font-size: 0.875rem;
}

    .section-details p {
        margin: 0.25rem 0;
    }

    .section-details span {
        font-weight: 600;
        color: #495057;
    }

.auto-scheduler-actions {
    margin-top: 1.5rem;
    text-align: right;
}

.save-schedule-btn {
    background-color: #28a745;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

    .save-schedule-btn:hover {
        background-color: #218838;
    }

/* Loading and Error States */
.auto-scheduler-loading {
    text-align: center;
    padding: 2rem;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0056b3;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.auto-scheduler-error {
    color: #dc3545;
    padding: 1rem;
    background-color: #f8d7da;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}

.no-schedule-message {
    text-align: center;
    color: #6c757d;
    padding: 2rem;
}
/* Auto Scheduler Styles */
.auto-scheduler {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.course-checklist {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

    .course-checklist h3 {
        margin-bottom: 20px;
        color: #333;
        text-align: center;
    }

.courses-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
}

.course-item {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #f8f9fa;
}

    .course-item label {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    .course-item input[type="checkbox"] {
        width: 18px;
        height: 18px;
    }

.course-code {
    font-weight: bold;
    color: #0056b3;
    min-width: 80px;
}

.course-name {
    color: #666;
    font-size: 0.9em;
}

.generate-schedule-btn,
.save-schedule-btn,
.back-to-courses-btn {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    background-color: #0056b3;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .generate-schedule-btn:hover,
    .save-schedule-btn:hover {
        background-color: #004494;
    }

.back-to-courses-btn {
    background-color: #6c757d;
}

    .back-to-courses-btn:hover {
        background-color: #5a6268;
    }

.recommended-sections {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.section-card {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

    .section-card h4 {
        color: #0056b3;
        margin-bottom: 10px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 5px;
    }

.section-details p {
    margin: 8px 0;
    font-size: 0.9em;
}

.section-details span {
    font-weight: bold;
    color: #495057;
    margin-right: 5px;
}

.auto-scheduler-loading {
    text-align: center;
    padding: 40px;
}

    .auto-scheduler-loading .spinner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #0056b3;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 20px auto;
    }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.no-schedule-message {
    text-align: center;
    color: #666;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 4px;
    margin-top: 20px;
}