/* Main layout */
.courses-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.courses-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
}

/* Search section */
.search-section {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.code-search-container {
    position: relative;
    margin-bottom: 1.5rem;
}

.search-dropdown-wrapper {
    position: relative;
}

.search-input {
    width: 88%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

    .search-input:focus {
        border-color: #4299e1;
        outline: none;
    }

.code-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
}

.code-dropdown-item {
    padding: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .code-dropdown-item:hover {
        background-color: #f7fafc;
    }

/* Selected courses */
.selected-courses {
    margin-top: 1.5rem;
}

    .selected-courses ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;
        list-style: none;
        padding: 0;
    }

    .selected-courses li {
        display: flex;
        align-items: center;
        background: #edf2f7;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        font-size: 0.875rem;
    }

.remove-button {
    margin-left: 0.5rem;
    background: none;
    border: none;
    color: #718096;
    cursor: pointer;
    font-size: 1.25rem;
    padding: 0 0.25rem;
}

/* Section list */
.section-list {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 1.5rem;
}

.section-item {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.section-details {
    flex: 1;
}

    .section-details h3 {
        margin: 0 0 1rem 0;
        color: #2d3748;
    }

    .section-details p {
        margin: 0.5rem 0;
        color: #4a5568;
    }

/* Signed-up sections */
.signed-up-section {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.signed-up-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    margin-bottom: 1rem;
}

.signed-up-details h5 {
    margin: 0 0 0.5rem 0;
    color: #2d3748;
}

/* Buttons */
.signup-button, .export-button {
    background: #4299e1;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .signup-button:hover, .export-button:hover {
        background: #3182ce;
    }

.remove-signedup-button {
    background: #e53e3e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

/* Pagination */
.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

    .pagination-controls button {
        background: #4299e1;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        cursor: pointer;
    }

        .pagination-controls button:disabled {
            background: #cbd5e0;
            cursor: not-allowed;
        }

/* Loading and error states */
.loading {
    text-align: center;
    padding: 2rem;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3182ce;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-container {
    text-align: center;
    padding: 2rem;
    color: #e53e3e;
}

.retry-button {
    background: #e53e3e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    margin-top: 1rem;
    cursor: pointer;
}

/* Validation errors */
.validation-errors {
    margin-top: 1rem;
    padding: 1rem;
    background: #fed7d7;
    border-radius: 4px;
    color: #c53030;
}

/* Responsive design */
@media (min-width: 768px) {
    .courses-container {
        grid-template-columns: 300px 1fr;
    }

    .signed-up-section {
        grid-column: 1 / -1;
    }
}
