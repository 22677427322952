/* src/HeaderBar/UserDropdown/UserDropdown.css */

.user-dropdown {
    position: relative;
    z-index: 1000;
}

.user-icon {
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 1.5rem; /* Make the icon bigger */
}

    .user-icon:hover {
        color: #0021A5; /* UF blue color on hover */
    }

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    padding: 0.5rem 0;
}

    .dropdown-menu button {
        width: 100%;
        padding: 0.75rem 1rem;
        border: none;
        background: none;
        text-align: left;
        cursor: pointer;
        font-size: 1rem;
        color: #333;
        display: block;
    }

        .dropdown-menu button:hover {
            background-color: #f5f5f5;
            color: #0021A5;
        }

/* Dropdown Animation */
.dropdown-menu {
    animation: dropdownAnimation 0.3s ease-out;
}

@keyframes dropdownAnimation {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
