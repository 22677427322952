
/* src/Styles/DegreePlan.css */

.degree-plan-page {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F5F5F5;
    max-width: 1000px;
    margin: 0 auto;
}

    .degree-plan-page h2 {
        color: #0C4DA2;
        text-align: center;
        margin-bottom: 30px;
    }

.plangridterm {
    margin-bottom: 40px;
}

.sc_plangrid {
    width: 100%;
    border-collapse: collapse;
}

    .sc_plangrid th,
    .sc_plangrid td {
        padding: 12px 15px;
        border: 1px solid #ddd;
        text-align: left;
    }

    .sc_plangrid th {
        background-color: #0C4DA2;
        color: white;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .sc_plangrid tr:nth-child(even) td {
        background-color: #f9f9f9;
    }

    /* Fixed column widths */
    .sc_plangrid th:first-child,
    .sc_plangrid td:first-child {
        width: 25%;
    }

    .sc_plangrid th:nth-child(2),
    .sc_plangrid td:nth-child(2) {
        width: 55%;
    }

    .sc_plangrid th:last-child,
    .sc_plangrid td:last-child {
        width: 20%;
        text-align: center;
    }

.total-credits {
    text-align: right;
    font-size: 1.1em;
    margin-top: 10px;
    font-weight: bold;
}
